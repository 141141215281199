<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <div class="flex-col text-wrapper">
        <span class="text_1">修改密码</span>
      </div>
      <div class="box_1">
        <span class="text_2">请输入密码</span>
        <div class="flex-row section_2">
          <img src="../../assets/suo.png" class="image_4" />
          <input type="text" class="text_3" v-model="password" placeholder="请输入原密码" />
        </div>
        <div class="flex-row section_3">
          <img src="../../assets/suo.png" class="image_4" />
          <input type="text" class="text_4" v-model="Npassword" placeholder="请输入新密码"/>
        </div>
        <div :class="['flex-col', 'items-center', 'button',{button_1:isLogin}]" @click="oko(isLogin)">
          <span>确认修改</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {revise} from '@/api/Property/Revise/Revise'
import Vue from 'vue';
import { Toast } from 'vant';
export default {
  data() {
    return {
      password: '',
      Npassword: '',
    }
  },
  computed: {
    isLogin() {
      let boo = false
      if (this.password && this.Npassword) {
        boo = true
      }
      return boo
    },
    
  },
  methods:{
    oko(val){
      if(this.password!==''&&this.Npassword!==''){
        if(val){
          revise({
            password:this.password,
            new_password:this.Npassword
          }).then(res=>{
            console.log(res);
            if(res.data.Result===200){
              Toast('修改成功');
              this.$router.push('/property/login')
            }
          })
        }
      }else{
        Toast.fail('请输入密码');
      }
    }
  },
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
Vue.use(Toast);

</script>

<style scoped>
@import '../../assets/common.css';
.image_4 {
  width: 1.5rem;
  height: 1.5rem;
}
.page {
  width: 100%;
  overflow: hidden;
}
.header {
  padding-top: 0.063rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  height: 2.81rem;
}
.group_1 {
  flex: 1 1 auto;
  overflow-y: auto;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  background-color: rgb(237, 237, 237);
}
.box_1 {
  overflow-x: hidden;
  margin-top: -1.5rem;
  background-color: #f5f5f5;
  border-radius: 1.2rem 1.2rem 0 0;
  padding-top: 2.5rem;
}
.section_2,
.section_3 {
  margin: 1.56rem 1.25rem 0;
  padding: 1rem 0.88rem 0.94rem;
  color: rgb(153, 153, 153);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  background: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  margin: 3.69rem 1.25rem 8rem;
  padding: 1.13rem 0 1rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  background: #e2e2e2;
  background-repeat: no-repeat;
}
.button_1 {
  background-color: #005ddb;
  color: #fff;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.text-wrapper {
  padding: 5.88rem 0 6.75rem;
  color: rgb(255, 255, 255);
  font-size: 1.88rem;
  line-height: 1.75rem;
  white-space: nowrap;
  background: #0355d7 url(../../assets/bg.png) right no-repeat;
}
.text_2 {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  margin-left: 1.31rem;
}
.text_3,
.text_4 {
  flex: 1;
  margin-left: 0.5rem;
  border: 0;
  outline: none;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.text_1 {
  text-align: left;
  margin-left: 1.31rem;
}
.box_1 {
    height: 72vh;
}
</style>
